import { FC } from 'react'

import { HEADERS } from 'constants/privacy'

import './PrivacyContentTable.scss'

const PrivacyContentTable: FC = () => {
  return (
    <div className='content-table'>
      <div id='toc' className='content-table-title'>
        <span>
          <strong>TABLE OF CONTENTS</strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      {HEADERS.map((HEADER: { header: string; link: string }, index: number) => {
        return (
          <div key={index} className='content-table-link'>
            <a href={HEADER.link}>
              <span>{HEADER.header}</span>
            </a>
          </div>
        )
      })}
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  )
}

export default PrivacyContentTable
