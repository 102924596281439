export const TEAM = 'team'
export const TEAMS = 'teams'

export const GET_TEAM_API_URL = 'api/v1/FUTSAL/teams'
export const GET_ALL_TEAMS_API_URL = 'api/v1/FUTSAL/teams/list'

export const MESSAGE_NO_TEAMS_CREATED = `No ${TEAMS}.`

export const SUBSCRIPTION_STATUS_COLOR = {
  ACTIVE: '--color-yellow-2',
  INACTIVE: '--color-red-3',
  TRIAL: '--color-blue-1',
  NOT_SUBSCRIBED_YET: '--color-grey-4',
}

export const SUBSCRIPTION_STATUS_NOT_SUBSCRIBED_YET = 'NOT SUBSCRIBED YET'

export const TEAM_FIRST_COLOR_PLACEHOLDER = '#ffffff'
export const TEAM_SECOND_COLOR_PLACEHOLDER = '#000000'

export enum TEAM_ROLES {
  TEAM_USER = 'ROLE_TEAM_USER',
  TEAM_ADMIN = 'ROLE_TEAM_ADMIN',
  TEAM_OWNER = 'ROLE_TEAM_OWNER',
}
