import { Dispatch, FC, SetStateAction } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import './SidePanel.scss'
import { useWindowResize } from 'hooks/useWindowSize'

interface SidePanelProps {
  isSidePanelTriggerClicked: boolean
  setIsSidePanelTriggerClicked: Dispatch<SetStateAction<boolean>>
}
const SidePanel: FC<SidePanelProps> = ({
  isSidePanelTriggerClicked,
  setIsSidePanelTriggerClicked,
}) => {
  const [width] = useWindowResize()

  return (
    <div
      className='privacy-side-panel'
      style={isSidePanelTriggerClicked ? { display: '', right: '0' } : { display: 'none' }}
    >
      <div
        id='side-panel-trigger-close'
        className='privacy-side-panel-trigger'
        onClick={() => {
          setIsSidePanelTriggerClicked(false)
          document.body.style.backgroundColor = 'white'
        }}
      >
        <a href='#'>{width > 976 ? <FontAwesomeIcon icon={faTimes} /> : <>X</>}</a>
      </div>
      <div className='privacy-side-panel-wrap'>
        <div className='privacy-side-panel-wrap-widget'>
          <h4>About Nistruct</h4>
          <div className='privacy-side-panel-wrap-widget-links'>
            <address style={{ lineHeight: '1.7' }}>
              Ulica Mladih 4
              <br />
              18000 Niš, Serbia
              <br />
            </address>
            <abbr title='Phone number'>Phone: </abbr>
            (381) 69 1662939
            <br />
            <abbr title='Email Address'>Email: </abbr>
            info@nistruct.com
          </div>
        </div>
        <div className='privacy-side-panel-wrap-widget'>
          <h4>Connect Socially</h4>
          <div className='privacy-side-panel-wrap-widget-icon'>
            <a
              className='privacy-side-panel-wrap-widget-icon-facebook'
              href='https://www.facebook.com/nistruct'
              rel='noopener noreferrer'
              target='_blank'
            >
              <FontAwesomeIcon icon={faFacebook} size='2x' />
            </a>
            <a
              href='https://github.com/nistruct'
              rel='noopener noreferrer'
              target='_blank'
              className='privacy-side-panel-wrap-widget-icon-github'
            >
              <FontAwesomeIcon icon={faGithub} size='2x' />
            </a>
            <a
              href='https://linkedin.com/company/nistruct'
              rel='noopener noreferrer'
              target='_blank'
              className='privacy-side-panel-wrap-widget-icon-linkedin'
            >
              <FontAwesomeIcon icon={faLinkedin} size='2x' />
            </a>
            <a
              href='https://instagram.com/nistruct'
              rel='noopener noreferrer'
              target='_blank'
              className='privacy-side-panel-wrap-widget-icon-instagram'
            >
              <FontAwesomeIcon icon={faInstagram} size='2x' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidePanel
