import { FC, /* useEffect, */ useRef, useState } from 'react'

import apple from 'assets/images/appStore.png'
import google from 'assets/images/googlePlay.png'
import elipseMan from 'assets/images/Ellipse 54.png'
import elipseTablet from 'assets/images/Ellipse 55.png'
import bannerWebsite from 'assets/images/Banner Website copy 1.png'
// import taster1 from 'assets/images/Taster1.png'
// import taster2 from 'assets/images/Taster2.png'

import './Header.scss'
import Pill from 'components/Pill'
import { useWindowResize } from 'hooks/useWindowSize'

const Header: FC = () => {
  const [isMouseInside, setIsMouseInside] = useState(false)
  const secondDivRef = useRef<HTMLDivElement | null>(null)
  const [width] = useWindowResize()
  // const [expanded1, setExpanded1] = useState(false)
  // const [expanded2, setExpanded2] = useState(false)

  // useEffect(() => {
  //   const interval2 = setInterval(() => {
  //     setExpanded2((prevExpanded) => !prevExpanded)
  //   }, 8000)

  //   const interval1 = setInterval(() => {
  //     setExpanded1((prevExpanded) => !prevExpanded)
  //   }, 7000)

  //   return () => {
  //     clearInterval(interval2)
  //     clearInterval(interval1)
  //   }
  // }, [])

  const handleTouchStart = () => {
    setIsMouseInside(true)
  }

  const handleTouchEnd = () => {
    setIsMouseInside(false)
  }

  const getHeightOfSecondDiv = () => {
    if (secondDivRef.current) {
      const height = secondDivRef.current.offsetHeight * 1.02

      return height
    }

    return 10
  }

  const handleMouseEnter = () => {
    setIsMouseInside(true)
  }

  const handleMouseLeave = () => {
    setIsMouseInside(false)
  }

  return (
    <div
      id='download-app'
      className='header'
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {width >= 685 && (
        <Pill
          className={`header-pill-horizontal ${isMouseInside ? 'visible' : ''}`}
          style={{ height: getHeightOfSecondDiv() }}
        />
      )}
      {width >= 685 && (
        <Pill
          className={`header-pill-angled ${isMouseInside ? 'visible' : ''}`}
          style={{ height: getHeightOfSecondDiv() }}
        />
      )}
      <div className='header-nonpill' ref={secondDivRef}>
        <div className='header-nonpill-content'>
          <div className='header-nonpill-content-title'>
            {/* <p>Join the future of futsal tracking with our tablet app</p> */}
            <p>
              Get the FutsalStats tablet app <span>FREE</span> during the 2024 Futsal World Cup!
            </p>
          </div>
          <div className='header-nonpill-content-text'>
            <p>Download your app from:</p>
          </div>
          <div className='header-nonpill-content-actions'>
            <a
              className='header-nonpill-content-actions-apple'
              href='https://kwokb.app.link/WyxPgiVjICb'
              style={{ background: '#A6A6A6' }}
            >
              <img src={apple} alt='Get it on App Store' style={{ borderRadius: '10px' }} />
            </a>
            <a
              className='header-nonpill-content-actions-google'
              href='https://kwokb.app.link/WyxPgiVjICb'
              style={{ background: '#A6A6A6' }}
            >
              <img src={google} alt='Get it on Google Play' style={{ borderRadius: '10px' }} />
            </a>
          </div>
        </div>
        <div
          className='header-nonpill-image'
          style={{
            width: width >= 685 ? 0.25 * width : 0.9 * width,
            height: width >= 685 ? 0.25 * width : 0.9 * width,
            margin: 'auto 0',
          }}
        >
          <div
            className='header-nonpill-image-container'
            style={{
              width: width >= 685 ? 0.3 * width : 0.9 * width,
              height: width >= 685 ? 0.3 * width : 0.9 * width,
              transform: width >= 685 ? 'translateY(-10%)' : '',
            }}
          >
            {/* <img
              src={taster1}
              alt='Taster 1 image'
              className={`header-nonpill-image-container-image1 ${
                expanded1 ? 'image1-expanded' : ''
              }`}
            />
            <img
              src={taster2}
              alt='Taster 2 image'
              className={`header-nonpill-image-container-image2 ${
                expanded2 ? 'image2-expanded' : ''
              }`}
            /> */}
            <img
              src={bannerWebsite}
              alt='Banner image'
              className='header-nonpill-image-container-banner'
            />
            <img
              src={elipseTablet}
              alt='Tablet Ellipse image'
              className='header-nonpill-image-container-tabell'
            />
            <img
              src={elipseMan}
              alt='Man Ellipse image'
              className='header-nonpill-image-container-manell'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
