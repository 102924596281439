import PrivacyNavbar from 'components/PrivacyNavbar'
import './Privacy.scss'
import PrivacyContent from 'components/PrivacyContent'
import PrivacyFooter from 'components/PrivacyFooter'
import SidePanel from 'components/SidePanel'
import { useEffect, useState } from 'react'
import nistructLogo from 'assets/images/favicon-16x16.png'

const Privacy = () => {
  const [isSidePanelTriggerClicked, setIsSidePanelTriggerClicked] = useState(false)

  useEffect(() => {
    document.title = 'Nistruct'
    const existingLogo = document.querySelector('link[rel="icon"]')
    const linkElement = document.createElement('link')
    linkElement.rel = 'icon'
    linkElement.href = nistructLogo
    existingLogo && document.head.removeChild(existingLogo)
    document.head.appendChild(linkElement)

    return () => {
      document.head.removeChild(linkElement)
      existingLogo && document.head.appendChild(existingLogo)
    }
  })

  return (
    <div className={`privacy ${isSidePanelTriggerClicked ? 'side-panel-open' : ''}`}>
      <div
        className='body-overlay'
        style={isSidePanelTriggerClicked ? { display: 'initial' } : { display: 'none' }}
        onClick={() => {
          setIsSidePanelTriggerClicked(false)
        }}
      ></div>
      <SidePanel
        isSidePanelTriggerClicked={isSidePanelTriggerClicked}
        setIsSidePanelTriggerClicked={setIsSidePanelTriggerClicked}
      />
      <div id='wrapper' className='privacy-header'>
        <PrivacyNavbar
          isSidePanelTriggerClicked={isSidePanelTriggerClicked}
          setIsSidePanelTriggerClicked={setIsSidePanelTriggerClicked}
        />
      </div>
      <PrivacyContent />
      <PrivacyFooter />
      <div id='gotoTop' className='privacy-gotoTop' style={{ display: 'block' }}></div>
    </div>
  )
}

export default Privacy
