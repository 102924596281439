import { FC } from 'react'

import './PrivacyContentSummary.scss'

const PrivacyContentSummary: FC = () => {
  return (
    <div className='content-summary'>
      <div className='content-summary-title'>
        <span>
          <strong>SUMMARY OF KEY POINTS</strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-subtitle'>
        <span>
          <strong>
            <em>
              This summary provides key points from our privacy notice, but you can find out more
              details about any of these topics by clicking the link following each key point or by
              using our table of contents below to find the section you are looking for. You can
              also click
            </em>
          </strong>
        </span>
        <a href='#toc'>
          <span>
            <strong>
              <em> here </em>
            </strong>
          </span>
        </a>
        <span>
          <strong>
            <em>to go directly to our table of contents.</em>
          </strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>What personal information do we process?</strong> When you visit, use, or navigate
          our Services, we may process personal information depending on how you interact with
          Nistruct doo and the Services, the choices you make, and the products and features you
          use. Click
        </span>
        <a href='#personalinfo'>
          <span> here </span>
        </a>
        <span>to learn more.</span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>Do we process any sensitive personal information? </strong>We do not process
          sensitive personal information.
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>Do we receive any information from third parties? </strong>We do not receive any
          information from third parties.
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>How do we process your information? </strong>We process your information to
          provide, improve, and administer our Services, communicate with you, for security and
          fraud prevention, and to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we have a valid legal
          reason to do so. Click
        </span>
        <a href='#infouse'>
          <span> here </span>
        </a>
        <span>to learn more.</span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>
            In what situation and with which parties do we share personal information?
          </strong>{' '}
          We may share information in specific situations and with specific third parties. Click
        </span>
        <a href='#whoshare'>
          <span> here </span>
        </a>
        <span>to learn more.</span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>How do we keep your information safe? </strong>We have organisational and
          technical processes and procedures in place to protect your personal information. However,
          no electronic transmission over the internet or information storage technology can be
          guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able to defeat your
          security and improperly collect, access, steal, or modify your information. Click
        </span>
        <a href='#infosafe'>
          <span> here </span>
        </a>
        <span>to learn more.</span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>What are your rights? </strong>Depending on where you are located geographically,
          the applicable privacy law may mean you have certain rights regarding your personal
          information. Click
        </span>
        <a href='#privacyrights'>
          <span>
            <span> here </span>
          </span>
        </a>
        <span>to learn more.</span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>How do you exercise your rights? </strong>The easiest way to exercise your rights
          is by filling out our data subject request form available
        </span>
        <a
          href='https://app.termly.io/notify/c7090724-f5af-4f5b-b163-21e842045e26'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span> here</span>
        </a>
        <span>
          , or by contacting us. We will consider and act upon any request in accordance with
          applicable data protection laws.
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-summary-paragraph'>
        <span>
          <strong>
            Want to learn more about what Nistruct doo does with any information we collect?{' '}
          </strong>
          Click
        </span>
        <a href='#toc'>
          <span> here </span>
        </a>
        <span>to review the notice in full.</span>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  )
}

export default PrivacyContentSummary
