import { FC, HTMLAttributes, ReactElement } from 'react'

import './Pill.scss'

interface IPillProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactElement[] | ReactElement
}

const Pill: FC<IPillProps> = ({ className, id, children, style }) => {
  return (
    <div id={id} className={className} style={style}>
      {children}
    </div>
  )
}

export default Pill
