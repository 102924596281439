import { Link } from 'react-router-dom'

import cx from 'classnames'

import logo from 'assets/images/logo.png'
import { ROUTES } from 'constants/routes'

import './Logo.scss'

interface ILogoProps {
  className?: string
  link?: string
}

const Logo = (props: ILogoProps) => {
  return (
    <div className={cx('logo', props.className)}>
      <Link to={props.link || ROUTES.PORTAL.PATH}>
        <img className='logo-image' src={logo} alt='Logo' />
      </Link>
    </div>
  )
}

export default Logo
