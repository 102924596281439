import { FC } from 'react'
import PrivacyContentHeader from './PrivacyContentHeader'
import PrivacyContentSummary from './PrivacyContentSummary'
import PrivacyContentTable from './PrivacyContentTable'
import PrivacyContentSection from './PrivacyContentSection'

const PrivacyContent: FC = () => {
  return (
    <section id='content'>
      <div>
        <PrivacyContentHeader />
        <PrivacyContentSummary />
        <PrivacyContentTable />
        <PrivacyContentSection />
      </div>
    </section>
  )
}

export default PrivacyContent
