import { FC } from 'react'

import Feature from 'components/Features/Feature/Feature'
import { FEATURES } from 'constants/website'

import './Features.scss'

const Features: FC = () => {
  return (
    <div className='features'>
      {Object.keys(FEATURES).map((key, index) => {
        return (
          <div className='features-content' key={key}>
            <Feature
              index={index}
              title={key}
              text={FEATURES[key].TEXT}
              imageUrl={FEATURES[key].IMAGE}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Features
