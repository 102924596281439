import { ReactNode } from 'react'
import { Country } from 'jsvat'

export interface ResponseErrorData {
  error: string
  error_description: string
}

export interface IAuthClient {
  access_token: string
  expires_in: number
  jti: string
  scope: string
  token_type: string
}

export interface IAuthUser {
  access_token: string
  expires_in: number
  jti: string
  refresh_token: string
  scope: string
  subscription: boolean
  token_type: string
}

export interface ISocialAuthUser {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
  scope: string
  token_type: string
}

export interface ISocialAuthUserVerified {
  access_token: string
  expires_in: number
  jti: string
  refresh_token: string
  scope: string
  token_type: string
}

export interface IUser {
  email: string
  firstName: string
  id: string
  jerseyNumber: number
  lastName: string
  lineupPosition: 'NONE' | 'RESERVE' | 'STARTER' | 'SUBSTITUTE'
  phoneNumber: string
  photoUrl: string
  position: string
  address?: string
}

export enum ELineupPosition {
  none = 'NONE',
  reserve = 'RESERVE',
  starter = 'STARTER',
  substitute = 'SUBSTITUTE',
}

export const initialUser = {
  email: '',
  firstName: '',
  id: '',
  jerseyNumber: 0,
  lastName: '',
  lineupPosition: ELineupPosition.none,
  phoneNumber: '',
  photoUrl: '',
  position: '',
  lastLogin: undefined,
  address: '',
}

export type TMembershipStatus = 'ACTIVE' | 'INACTIVE' | 'TRIAL'

export enum EMembershipStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  trial = 'TRIAL',
}

export type TSubscriptionType = 'YEARLY' | 'MONTHLY' | 'TRIAL'

export enum ESubscriptionType {
  yearly = 'YEARLY',
  monthly = 'MONTHLY',
  trial = 'TRIAL',
}

export interface ITeamSubscriptionData {
  cancelled: boolean
  endDate: string
  externalId: string
  id: string
  membershipStatus: TMembershipStatus
  startDate: string
  subscriptionType: TSubscriptionType
  userId: string
}

export const initialTeamSubscription = {
  cancelled: false,
  endDate: '',
  externalId: '',
  id: '',
  membershipStatus: EMembershipStatus.inactive,
  startDate: '',
  subscriptionType: ESubscriptionType.trial,
  userId: '',
}

export interface ITeamTrialAvailability {
  isTrialAvailable: boolean
}

export interface ISubscriptionCheckout {
  redirectUrl: string
}

export enum ESubscriptionCancelPeriod {
  cancelAtTheEndOfTheCurrentBillingPeriod = 'CANCEL_AT_THE_END_OF_THE_CURRENT_BILLING_PERIOD',
  cancelImmediately = 'CANCEL_IMMEDIATELY',
}

export enum ESubscriptionStart {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type TSubscriptionStart = ESubscriptionStart.MONTHLY | ESubscriptionStart.YEARLY | 'TRIAL'

export type TSubscriptionCancelPeriod =
  | ESubscriptionCancelPeriod.cancelAtTheEndOfTheCurrentBillingPeriod
  | ESubscriptionCancelPeriod.cancelImmediately

export const initialPaymentMethod = {
  brand: 'No data',
  city: 'No data',
  country: 'No data',
  expMonth: 0,
  expYear: 0,
  id: '',
  last4: '',
  line1: 'No data',
  line2: 'No data',
  postalNumber: 'No data',
}

export interface ICreatePaymentMethodResponse {
  redirectUrl: string
}

export interface IVatNumberResponse {
  vatNumber: string
}
export interface IGetAllTeamsResponse {
  teams: ITeamBase[]
}

export interface IInvoicesListResponse {
  invoices: IInvoice[]
}

export interface IInvoice {
  amountDue: number
  amountPaid: number
  externalInvoicePage: string
  externalPdfLink: string
  id: string
  issueDate: string
  paid: string
  paidAtDate: string
  periodEndDate: string
  periodStartDate: string
}

export interface ITeamBase {
  firstColor: string
  id: string
  logoUrl: string
  membershipStatus: TMembershipStatus
  name: string
  secondColor: string
}

export interface ITeam extends ITeamBase {
  city: string
  country: string
  numberOfActivePlayers: number
  numberOfPlayers: number
  players: IUser[]
}

export const initialTeamBase = {
  firstColor: '#ffffff',
  id: '',
  logoUrl: '',
  membershipStatus: EMembershipStatus.inactive,
  name: 'No data',
  secondColor: '#000000',
}

export const initialTeam = {
  city: 'No data',
  country: 'No data',
  firstColor: '#ffffff',
  id: '',
  logoUrl: '',
  membershipStatus: EMembershipStatus.inactive,
  name: 'No data',
  numberOfActivePlayers: 0,
  numberOfPlayers: 0,
  players: [],
  secondColor: '#000000',
}

export interface IProps {
  children: ReactNode
}

export type GlobalContextType = {
  updateUser: (state: IUser) => void
  user: IUser
}

export type TeamsContextType = {
  teams: ITeamBase[]
}

export const initialCountry: Country = {
  name: '',
  codes: [],
  calcFn: () => true,
  rules: {
    multipliers: {
      key: [],
    },
    regex: [],
  },
}

export interface IDropdownItem {
  id: string
  label1: string
  label2: string
  separator: string
}

export type ColumnDefinitionType<T, K extends keyof T> = {
  key: K
  header: string
  type: string
  width?: number
}

export type Company = {
  id: string
  name: string
  address: string
  vatNumber: string
  email: string
}

export type ICompany = {
  name: string
  address: string
  vatNumber: string
  email: string
}
