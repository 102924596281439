import { ChangeEvent, FC, FormEvent, useState } from 'react'

import Button from 'components/Button'
import Input from 'components/Input'
import Pill from 'components/Pill'
import { useWindowResize } from 'hooks/useWindowSize'

import './NewFeaturesSection.scss'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

const postUrl = process.env.REACT_APP_MAILCHIMP_POST_URL || 'post_url'
const tag = process.env.REACT_APP_MAILCHIMP_TAG || '1234567'

interface SubscribeToNewsFormProps {
  onValidated: (formData: { EMAIL: string; tags: string[] }) => void
}

const SubscribeToNewsForm: FC<SubscribeToNewsFormProps> = ({ onValidated }) => {
  const [width] = useWindowResize()
  const [email, setEmail] = useState('')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setEmail(newValue)
  }

  const handleSubscribe = (e: FormEvent) => {
    try {
      e.preventDefault()
      if (!email) {
        alert('Please enter the email')
        return
      }
      if (email.indexOf('@') === -1) {
        alert('Invalid email')
        return
      }
      email && email.indexOf('@') > -1 && onValidated({ EMAIL: email, tags: [tag] })
      alert('Successfully subscribed to newsletters')
    } catch (err: unknown) {
      alert((err as { message: string }).message)
    }
  }

  return (
    <form onSubmit={handleSubscribe} className='new-features-box-actions-form'>
      <div className='new-features-box-actions-form-input'>
        <Input
          id='new-feature'
          placeholder='Enter your mail here'
          value={email}
          onChange={handleInputChange}
          type='text'
          disabled={false}
          style={{
            color: '#7E7E7E',
            fontFamily: 'var(--font-semi-bold)',
            minHeight: '26px',
            resize: 'vertical',
            height: 'fit-content',
            textAlign: 'center',
          }}
        />
      </div>
      <div className='new-features-box-actions-form-button'>
        <Button
          type='submit'
          text='Send me Newsletter'
          className='new-features-box-actions-form-button-submit'
          style={{
            border: 'none',
            background: '#000000',
            color: '#FFFFFF',
            borderRadius: '35px',
            padding: width > 550 ? '15px 32px 15px 32px' : '5px 10px 5px 10px',
            fontFamily: 'var(--font-bold)',
            fontSize: 'var(--font-size-s)',
            lineHeight: '20px',
            minHeight: '20px',
            height: width > 900 ? '40px' : 'fit-content',
            width: width > 550 ? 'max-content' : width > 450 ? 'fit-content' : '100px',
            marginLeft: width > 900 ? 'auto' : '0',
            textAlign: 'center',
          }}
        />
      </div>
    </form>
  )
}

const NewFeatureSection: FC = () => {
  return (
    <div className='new-features'>
      <Pill className='new-features-box'>
        <div className='new-features-box-title'>
          <p>More features coming soon!</p>
        </div>
        <div className='new-features-box-text'>
          <p>Leave us your email address and we will keep you posted</p>
        </div>
        <div className='new-features-box-actions'>
          <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe }) => (
              <SubscribeToNewsForm
                onValidated={(formData: { EMAIL: string; tags: string[] }) => subscribe(formData)}
              />
            )}
          />
        </div>
      </Pill>
    </div>
  )
}

export default NewFeatureSection
