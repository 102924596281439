import { FC } from 'react'

import './PrivacyContentHeader.scss'

const PrivacyContentHeader: FC = () => {
  return (
    <div className='content-header'>
      <div className='content-header-title'>
        <span>
          <strong>PRIVACY NOTICE</strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div className='content-header-subtitle'>
        <span>
          <strong>Last updated February 22, 2023</strong>
        </span>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div className='content-header-listTitle'>
        <span>
          This privacy notice for <span>Nistruct doo</span> {"('"}
          <strong>Company</strong>
          {"', '"}
          <strong>we</strong>
          {"', '"}
          <strong>us</strong>
          {"' or '"}
          <strong>our</strong>
          {"'), "}describes how and why we might collect, store, use, and/or share {"('"}
          <strong>process</strong>
          {"') "}your information when you use our services {"('"}
          <strong>Services</strong>
          {"'), "}such as when you:
        </span>
      </div>
      <ul className='content-header-list'>
        <li className='content-header-list-element'>
          <span>
            Download and use our mobile application {'('}FutsalStats{'), '}or any other application
            of ours that links to this privacy notice
          </span>
        </li>
        <li className='content-header-list-element'>
          <span>
            Engage with us in other related ways, including any sales, marketing, or events
          </span>
        </li>
      </ul>
      <div className='content-header-questions'>
        <span>
          <strong>Questions or concerns?</strong> Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with our policies and
          practices, please do not use our Services. If you still have any questions or concerns,
          please contact us at info@nistruct.com
        </span>
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  )
}

export default PrivacyContentHeader
