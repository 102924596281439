import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import { GoogleOAuthProvider } from '@react-oauth/google'

import App from './App'
import GlobalProvider from './contexts/global-context'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <App />
      </GoogleOAuthProvider>
    </GlobalProvider>
  </React.StrictMode>,
)

reportWebVitals()
