import ContactForm from 'components/ContactForm'
import Features from 'components/Features'
import Footer from 'components/Footer'
import Header from 'components/Header/Header'
import NewFeatureSection from 'components/NewFeaturesSection'
import Pricing from 'components/Pricing'
import Testimonials from 'components/Testimonials'
import WebsiteNavbar from 'components/WebsiteNavbar'

const Website = () => {
  return (
    <div className='site'>
      <WebsiteNavbar />
      <Header />
      <Features />
      <Testimonials />
      <Pricing />
      <NewFeatureSection />
      <Footer />
      <ContactForm />
    </div>
  )
}

export default Website
