import { FC } from 'react'

import Testimonial from 'components/Testimonial/Testimonial'
import { TESTIMONIALS } from 'constants/website'

import './Testimonials.scss'

const Testimonials: FC = () => {
  return (
    <div className='testimonials'>
      <div className='testimonials-header'>
        <p>See what our users think</p>
      </div>
      {Object.keys(TESTIMONIALS).map((key, index) => {
        return (
          <Testimonial
            key={index}
            image={TESTIMONIALS[key].IMAGE}
            title={key}
            text={TESTIMONIALS[key].TEXT}
            rating={TESTIMONIALS[key].RATING}
            index={index}
            position={TESTIMONIALS[key].POSITION}
          />
        )
      })}
    </div>
  )
}

export default Testimonials
