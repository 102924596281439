import { FC, useRef, useState } from 'react'

import cx from 'classnames'

import './Feature.scss'
import Pill from 'components/Pill'
import { useWindowResize } from 'hooks/useWindowSize'

interface IFeatureProps {
  className?: string
  index: number
  title: string
  text: string
  imageUrl: string
}

const Feature: FC<IFeatureProps> = ({ className, index, title, text, imageUrl }) => {
  const [isMouseInside, setIsMouseInside] = useState(false)
  const secondDivRef = useRef<HTMLDivElement | null>(null)
  const [width] = useWindowResize()

  const handleTouchStart = () => {
    setIsMouseInside(true)
  }

  const handleTouchEnd = () => {
    setIsMouseInside(false)
  }

  const getHeightOfSecondDiv = () => {
    if (secondDivRef.current) {
      let height = secondDivRef.current.offsetHeight * 1.05
      width < 650
        ? (height = secondDivRef.current.offsetHeight * 0.9)
        : width < 750
        ? (height = secondDivRef.current.offsetHeight * 0.8)
        : width < 950
        ? (height = secondDivRef.current.offsetHeight * 0.7)
        : width < 1300
        ? (height = secondDivRef.current.offsetHeight * 0.5)
        : (height = secondDivRef.current.offsetHeight * 0.3)

      return height
    }

    return 10
  }

  const handleMouseEnter = () => {
    setIsMouseInside(true)
  }

  const handleMouseLeave = () => {
    setIsMouseInside(false)
  }

  return (
    <div
      className={cx(className, 'feature')}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {index % 2 === 0 ? (
        <>
          {width >= 685 && <Pill
            className={`feature-pill-left ${isMouseInside ? 'visible' : ''}`}
            style={{
              height: getHeightOfSecondDiv(),
            }}
          />}
          <div className='feature-box' ref={secondDivRef}>
            <div className={cx(className, 'feature-box-img')}>
              <img src={imageUrl} alt='' />
            </div>
            <div className={cx(className, 'feature-box-content')}>
              <div className={cx(className, 'feature-box-content-title')}>
                <p>{title}</p>
              </div>
              <div className={cx(className, 'feature-box-content-text')}>
                <p>{text}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {width >= 685 && <Pill
            className={`feature-pill-right ${isMouseInside ? 'visible' : ''}`}
            style={{ height: getHeightOfSecondDiv() }}
          />}
          <div className='feature-box' ref={secondDivRef}>
            <div className={cx(className, 'feature-box-content')}>
              <div className={cx(className, 'feature-box-content-title')}>
                <p>{title}</p>
              </div>
              <div className={cx(className, 'feature-box-content-text')}>
                <p>{text}</p>
              </div>
            </div>
            <div className={cx(className, 'feature-box-img')}>
              <img src={imageUrl} alt='app screen' />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Feature
