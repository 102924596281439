import zlatan from 'assets/images/zlatan.png'
import micoMartic from 'assets/images/mico-martic-futsal.png'
import vojkan from 'assets/images/vojkan.png'
import manageTeam from 'assets/images/manage-team.png'
import statisticalAnalytics from 'assets/images/statistical-analytics.png'
import trackMatchEvents from 'assets/images/track-match-events.png'
import realTimeUpdates from 'assets/images/real-time-updates.png'

interface FEATURE {
  TEXT: string
  IMAGE: string
}

interface TESTIMONIAL extends FEATURE {
  RATING: number
  POSITION: string
}

export const FEATURES: { [key: string]: FEATURE } = {
  ['Track Match Events']: {
    TEXT: "Our futsal stats tracking tablet app allows you to track match events in real-time, capturing every goal, assist, foul, and yellow card with ease. With customisable tracking options, you can tailor the app to suit your needs and focus on the events that matter most to you. Whether you're a coach looking to make strategic decisions or a fan trying to stay up-to-date with the action on the court, our app ensures that you never miss a beat.",
    IMAGE: trackMatchEvents,
  },
  ['Real Time Updates']: {
    TEXT: "You'll never miss a moment of the action. Our app provides real-time updates, ensuring that you're always up-to-date on the latest match events, no matter where you are. Whether you're a coach, player, or fan, our app lets you follow along with the game as it happens. Stay informed and make data-driven decisions with our app's real-time updates, and take your game to the next level.",
    IMAGE: realTimeUpdates,
  },
  ['Manage Teams']: {
    TEXT: "You can easily manage multiple teams in one place. Our app allows you to create and manage different teams, each with their own set of statistics and match history. Whether you're a coach managing several teams or a player on multiple squads, our app keeps everything organised and accessible. You can quickly switch between teams to view their individual stats and compare performance, making it easier than ever to stay on top of your game. With our app you can streamline your workflow and focus on what really matters - winning.",
    IMAGE: manageTeam,
  },
  ['Statistical Analytics & Overview']: {
    TEXT: "Join the FutsalStats community and take control of your stats with our app. Get instant access to real-time stats and insights for you and your team. Whether you're a coach, player, or fan, our app will help you track, analyze and improve your performance. With our app, you'll have everything you need to dominate the field and achieve your goals",
    IMAGE: statisticalAnalytics,
  },
}

export const TESTIMONIALS: { [key: string]: TESTIMONIAL } = {
  ['Zlatan Maksimović']: {
    TEXT: 'FutsalStats has completely revolutionized the way I lead my team during a match and track my team’s progress. The real-time statistics make it easy to stay on top of player performance on the field.',
    RATING: 5.0,
    IMAGE: zlatan,
    POSITION: 'Zlatan Maksimović, Futsal trainer (Sweden)',
  },
  ['Mićo Martić']: {
    TEXT: 'Team will become more engaged and will have a better understanding of the coaching staff decision making when they can access the statistics and analyse it for themselves. Data driven futsal is made easy using this app.',
    RATING: 5.0,
    IMAGE: micoMartic,
    POSITION: 'Mićo Martić, FIFA/UEFA Futsal Coaching Instructor',
  },
  ['Vojkan Vukmirović']: {
    TEXT: 'This tablet app is a powerful tool helping analyse the player development and progress, as well as identifying crucial events in the match.',
    RATING: 5.0,
    IMAGE: vojkan,
    POSITION: 'Vojkan Vukmirović, Futsal coach (Stuttgarter Futsal Club, Germany)',
  },
}

export const PRICING = {
  YEARLY: '500$',
  MONTHLY: '50$',
}

export const mailListUrl = ''
