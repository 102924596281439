import { FC, useState } from 'react'

import './Pricing.scss'

import '@fortawesome/fontawesome-free/css/all.css'

const Pricing: FC = () => {
  const [isToggled, setIsToggled] = useState(false)

  const handleToggle = () => {
    setIsToggled(!isToggled)
  }

  return (
    <div id='pricing' className='pricings'>
      <div className='pricings-description'>
        <p className='pricings-description-header'>FutsalStats Pricing Plans</p>
        <p className='pricings-description-text'>
          Explore FutsalStats pricing plans tailored to your team’s needs. Whether you manage one
          team or multiple, our flexible plans offer real-time stats, performance tracking, and
          advanced insights to help you stay ahead.
        </p>
      </div>
      <div className='pricings-period'>
        <p className='pricings-period-monthly'>Billed Monthly</p>
        <div className={`toggle-container ${isToggled ? 'on' : 'off'}`} onClick={handleToggle}>
          <div className='toggle-circle' />
        </div>
        <p className='pricings-period-yearly'>Half a Season</p>
      </div>
      <div className='pricings-plans'>
        <div className='pricings-plans-division'>
          <div className='pricings-plans-division-description'>
            <p className='pricings-plans-division-description-header'>Division</p>
            <p className='pricings-plans-division-description-start'>Starting from:</p>
            {!isToggled ? (
              <p className='pricings-plans-division-description-price'>
                $40<span>*</span>
              </p>
            ) : (
              <p className='pricings-plans-division-description-price'>
                $200<span>*</span>
              </p>
            )}
            <p className='pricings-plans-division-description-text'>Perfect for small teams</p>
          </div>
          <div className='pricings-plans-division-benefits'>
            <p>Includes 1 team</p>
            <p>Track team stats</p>
            <p>Performance insights</p>
          </div>
        </div>
        <div className='pricings-plans-championship'>
          <div className='pricings-plans-most-popular'>Most popular</div>
          <div className='pricings-plans-championship-description'>
            <p className='pricings-plans-championship-description-header'>Championship</p>
            <p className='pricings-plans-division-description-start'>Starting from:</p>
            {!isToggled ? (
              <p className='pricings-plans-championship-description-price'>
                $108<span>*</span>
              </p>
            ) : (
              <p className='pricings-plans-championship-description-price'>
                $544<span>*</span>
              </p>
            )}
            <p className='pricings-plans-championship-description-text'>
              Ideal for coaches managing multiple teams
            </p>
          </div>
          <div className='pricings-plans-championship-benefits'>
            <p>Includes 3 team</p>
            <p>Track multiple teams</p>
            <p>Compare team performance</p>
          </div>
        </div>
        <div className='pricings-plans-premier'>
          <div className='pricings-plans-premier-description'>
            <p className='pricings-plans-premier-description-header'>Premier</p>
            <p className='pricings-plans-division-description-start'>Starting from:</p>
            {!isToggled ? (
              <p className='pricings-plans-premier-description-price'>
                $160<span>*</span>
              </p>
            ) : (
              <p className='pricings-plans-premier-description-price'>
                $800<span>*</span>
              </p>
            )}
            <p className='pricings-plans-premier-description-text'>
              Ultimate control for serious competitors
            </p>
          </div>
          <div className='pricings-plans-premier-benefits'>
            <p>Includes 5 team</p>
            <p>Advanced team comparison</p>
            <p>All Championship Plan features</p>
          </div>
        </div>
      </div>
      <p className='pricings-taxes'>*Prices may differ depending on taxes in your country</p>
    </div>
  )
}

export default Pricing
