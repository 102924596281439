import { FC } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons'

import './PrivacyFooter.scss'

const PrivacyFooter: FC = () => {
  return (
    <footer id='footer' className='privacy-footer'>
      <div className='privacy-footer-container'>
        <div className='privacy-footer-container-links'>
          <h4 className='privacy-footer-container-links-header'>Site Links</h4>
          <ul className='privacy-footer-container-links-list'>
            <li className='privacy-footer-container-links-list-item'>
              <a href='#header'>Top</a>
            </li>
            <li className='privacy-footer-container-links-list-item'>
              <a href='/website'>Website</a>
            </li>
          </ul>
        </div>
        <div className='privacy-footer-container-contact'>
          <h4 className='privacy-footer-container-contact-header'>CONTACT</h4>
          <p className='privacy-footer-container-contact-content'>
            <a href='tel: +381691046701'>(381) 69 1046701</a>
            <br />
            <a href='mailto: info@nistruct.com'>info@nistruct.com</a>
          </p>
          <div className='privacy-footer-container-contact-icons'>
            <a href='https://www.facebook.com/nistruct' title='Facebook'>
              <FontAwesomeIcon icon={faFacebook} size='2x' />
            </a>
            <a href='https://www.linkedin.com/company/nistruct/' title='LinkedIn'>
              <FontAwesomeIcon icon={faLinkedin} size='2x' />
            </a>
            <a href='https://github.com/nistruct' title='Github'>
              <FontAwesomeIcon icon={faGithub} size='2x' />
            </a>
            <a href='https://www.instagram.com/nistruct' title='Instagram'>
              <FontAwesomeIcon icon={faInstagram} size='2x' />
            </a>
          </div>
        </div>
      </div>
      <div className='privacy-footer-copyrights'>
        Copyrights Nistruct 2020 | All rights reserved
      </div>
    </footer>
  )
}

export default PrivacyFooter
