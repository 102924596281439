import { FC, useState, Dispatch, SetStateAction } from 'react'

import nistructLogo3x from 'assets/images/Black@3x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import './PrivacyNavbar.scss'
import { useWindowResize } from 'hooks/useWindowSize'

interface IPrivacyNavbarProps {
  isSidePanelTriggerClicked: boolean
  setIsSidePanelTriggerClicked: Dispatch<SetStateAction<boolean>>
}

const PrivacyNavbar: FC<IPrivacyNavbarProps> = ({
  isSidePanelTriggerClicked,
  setIsSidePanelTriggerClicked,
}) => {
  const [isMenuTriggerClicked, setIsMenuTriggerClicked] = useState(false)
  const [isMouseInside, setIsMouseInside] = useState(false)
  const [index, setIndex] = useState(-1)
  const [width] = useWindowResize()

  const handleMouseEnter = (index: number) => {
    setIsMouseInside(true)
    setIndex(index)
  }

  const handleMouseLeave = () => {
    setIsMouseInside(false)
    setIndex(-1)
  }

  return (
    <header id='header' className='navbar'>
      <div id='header-wrap' className='navbar-wrap'>
        <div className='navbar-wrap-row'>
          <div id='logo' className='navbar-wrap-row-logo'>
            <a href='/website' className='navbar-wrap-row-logo-retina'>
              <img src={nistructLogo3x} alt='Nistruct logo' />
            </a>
          </div>
          <div
            className='navbar-wrap-row-misc'
            onClick={() => {
              setIsSidePanelTriggerClicked(!isSidePanelTriggerClicked)
            }}
          >
            <div className='navbar-wrap-row-misc-icon'>
              <a href='#'>
                <FontAwesomeIcon icon={faEllipsisV} />
              </a>
            </div>
          </div>
          <div
            id='primary-menu-trigger'
            onClick={() => {
              setIsMenuTriggerClicked(!isMenuTriggerClicked)
            }}
          >
            <FontAwesomeIcon icon={isMenuTriggerClicked ? faTimes : faBars} />
          </div>
          <nav className='navbar-wrap-row-primary-menu'>
            <ul
              className='navbar-wrap-row-primary-menu-container'
              style={
                width > 976
                  ? { display: 'flex' }
                  : isMenuTriggerClicked
                  ? { display: 'block' }
                  : { display: 'none' }
              }
            >
              <li
                className={`navbar-wrap-row-primary-menu-container-item ${
                  isMouseInside && index === 0 ? 'current' : ''
                }`}
                onMouseEnter={() => handleMouseEnter(0)}
                onTouchStart={() => handleMouseEnter(0)}
                onMouseLeave={() => handleMouseLeave()}
                onTouchEnd={() => handleMouseLeave()}
              >
                <a href='/website' className='navbar-wrap-row-primary-menu-container-item-link'>
                  <div>Home</div>
                </a>
              </li>
              <li
                className={`navbar-wrap-row-primary-menu-container-item ${
                  isMouseInside && index === 4 ? 'current' : ''
                }`}
                style={{ borderBottom: '0px' }}
                onMouseEnter={() => handleMouseEnter(4)}
                onTouchStart={() => handleMouseEnter(4)}
                onMouseLeave={() => handleMouseLeave()}
                onTouchEnd={() => handleMouseLeave()}
              >
                <a href='#contact' className='navbar-wrap-row-primary-menu-container-item-link'>
                  <div>Contact</div>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className='navbar-wrap-clone'></div>
    </header>
  )
}

export default PrivacyNavbar
