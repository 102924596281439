import { FC } from 'react'

import './PrivacyContentSection.scss'
import { A_REL, HEADERS_TEXT, PARAGRAPH_TYPES, textProps } from 'constants/privacy'

interface IInShortProps {
  paragraph: textProps
  id?: string
}

interface IListElementsProps {
  paragraph: { [key: string]: textProps[] }
  id?: string
}

interface ITableProps {
  paragraph: { [key: string]: textProps[] }
  id?: string
  headers: textProps[]
}

interface ItextProps {
  text: string
  bold: boolean
  underline: boolean
  italic: boolean
  a?: boolean
  link?: string
  target?: string
}

const Text: FC<ItextProps> = ({ text, bold, underline, italic, a, link, target }) => {
  return (
    <>
      {a ? (
        <a href={link} target={target} rel={A_REL[target ? target : '']}>
          {bold ? (
            <strong>
              {italic ? (
                <em>{underline ? <u>{text}</u> : <>{text}</>}</em>
              ) : (
                <>{underline ? <u>{text}</u> : <>{text}</>}</>
              )}
            </strong>
          ) : (
            <>
              {italic ? (
                <em>{underline ? <u>{text}</u> : <>{text}</>}</em>
              ) : (
                <>{underline ? <u>{text}</u> : <>{text}</>}</>
              )}
            </>
          )}
        </a>
      ) : (
        <>
          {bold ? (
            <strong>
              {italic ? (
                <em>{underline ? <u>{text}</u> : <>{text}</>}</em>
              ) : (
                <>{underline ? <u>{text}</u> : <>{text}</>}</>
              )}
            </strong>
          ) : (
            <>
              {italic ? (
                <em>{underline ? <u>{text}</u> : <>{text}</>}</em>
              ) : (
                <>{underline ? <u>{text}</u> : <>{text}</>}</>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
const InShort: FC<IInShortProps> = ({ paragraph, id }) => {
  return (
    <>
      <div id={id ? id : ''} className='content-section-inshort'>
        <span>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <Text
            text={paragraph.text}
            a={paragraph.a}
            link={paragraph.link}
            target={paragraph.target}
            bold={paragraph.bold}
            italic={paragraph.italic}
            underline={paragraph.underline}
          />
        </span>
      </div>
      {paragraph.breakLine && (
        <div>
          <br />
        </div>
      )}
    </>
  )
}

const ListElements: FC<IListElementsProps> = ({ paragraph, id }) => {
  return (
    <ul id={id ? id : ''} className='content-section-list'>
      {Object.keys(paragraph).map((listElement: string, index: number) => {
        return (
          <li key={index} className='content-section-list-element'>
            <span>
              {paragraph[listElement].map((text: textProps) => {
                return (
                  <>
                    <Text
                      text={text.text}
                      a={text.a}
                      link={text.link}
                      target={text.target}
                      bold={text.bold}
                      italic={text.italic}
                      underline={text.underline}
                    />
                    {text.breakLine && (
                      <div>
                        <br />
                      </div>
                    )}
                  </>
                )
              })}
            </span>
          </li>
        )
      })}
    </ul>
  )
}

const Table: FC<ITableProps> = ({ paragraph, id, headers }) => {
  return (
    <table id={id ? id : ''} className='content-section-table'>
      <thead>
        {headers.map((header: textProps, index: number) => {
          return (
            <th key={`${index}${id}`}>
              <Text
                text={header.text}
                a={header.a}
                link={header.link}
                target={header.target}
                bold={header.bold}
                italic={header.italic}
                underline={header.underline}
              />
              {header.breakLine && (
                <div>
                  <br />
                </div>
              )}
            </th>
          )
        })}
      </thead>
      <tbody>
        {Object.keys(paragraph).map((tableRow: string, index: number) => {
          return (
            <tr key={index}>
              {paragraph[tableRow].map((tableCell: textProps, indexCell: number) => {
                return (
                  <td
                    key={indexCell}
                    style={
                      indexCell === 2
                        ? { textAlign: 'center', verticalAlign: 'middle' }
                        : { verticalAlign: 'middle' }
                    }
                  >
                    <Text
                      text={tableCell.text}
                      a={tableCell.a}
                      link={tableCell.link}
                      target={tableCell.target}
                      bold={tableCell.bold}
                      italic={tableCell.italic}
                      underline={tableCell.underline}
                    />
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const PrivacyContentSection: FC = () => {
  return (
    <div className='content-section'>
      {Object.keys(HEADERS_TEXT).map((key: string, indexKeys: number) => {
        const ids = HEADERS_TEXT[key].id?.split(' ')
        return (
          <>
            <div id={(ids as string[])[0]} className='content-section-title'>
              {ids?.map((id: string, indexIds: number) => {
                if (indexIds === 0) return
                return <span key={`${indexIds}${indexKeys}`} id={id}></span>
              })}
              <span>
                <strong>{key}</strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            {HEADERS_TEXT[key].paragraphs.map(
              (paragraph: {
                type: string
                id?: string
                headers?: textProps[]
                paragraph: textProps | textProps[] | { [key: string]: textProps[] }
              }) => {
                switch (paragraph.type) {
                  case PARAGRAPH_TYPES.IN_SHORT:
                    return (
                      <InShort paragraph={paragraph.paragraph as textProps} id={paragraph.id} />
                    )
                  case PARAGRAPH_TYPES.LIST_ELEMENTS:
                    return (
                      <ListElements
                        paragraph={paragraph.paragraph as { [key: string]: textProps[] }}
                        id={paragraph.id}
                      />
                    )
                  case PARAGRAPH_TYPES.LIST_TITLE:
                    return (
                      <div
                        id={paragraph.id ? paragraph.id : ''}
                        className='content-section-listTitle'
                      >
                        <span>
                          {Array.isArray(paragraph.paragraph) ? (
                            (paragraph.paragraph as textProps[]).map(
                              (text: textProps, index: number) => {
                                return (
                                  <>
                                    <Text
                                      key={index}
                                      text={text.text}
                                      a={text.a}
                                      link={text.link}
                                      target={text.target}
                                      bold={text.bold}
                                      italic={text.italic}
                                      underline={text.underline}
                                    />
                                    {text.breakLine && (
                                      <div>
                                        <br />
                                      </div>
                                    )}
                                  </>
                                )
                              },
                            )
                          ) : (
                            <>
                              <Text
                                text={(paragraph.paragraph as textProps).text}
                                a={(paragraph.paragraph as textProps).a}
                                link={(paragraph.paragraph as textProps).link}
                                target={(paragraph.paragraph as textProps).target}
                                bold={(paragraph.paragraph as textProps).bold}
                                italic={(paragraph.paragraph as textProps).italic}
                                underline={(paragraph.paragraph as textProps).underline}
                              />
                              {(paragraph.paragraph as textProps).breakLine && (
                                <div>
                                  <br />
                                </div>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                    )
                  case PARAGRAPH_TYPES.SPAN:
                    return (
                      <>
                        <div id={paragraph.id ? paragraph.id : ''} className='content-section-text'>
                          <span>
                            {Array.isArray(paragraph.paragraph) ? (
                              (paragraph.paragraph as textProps[]).map(
                                (text: textProps, index: number) => {
                                  return (
                                    <>
                                      <Text
                                        key={index}
                                        text={text.text}
                                        a={text.a}
                                        link={text.link}
                                        target={text.target}
                                        bold={text.bold}
                                        italic={text.italic}
                                        underline={text.underline}
                                      />
                                      {text.breakLine && (
                                        <div>
                                          <br />
                                        </div>
                                      )}
                                    </>
                                  )
                                },
                              )
                            ) : (
                              <>
                                <Text
                                  text={(paragraph.paragraph as textProps).text}
                                  a={(paragraph.paragraph as textProps).a}
                                  link={(paragraph.paragraph as textProps).link}
                                  target={(paragraph.paragraph as textProps).target}
                                  bold={(paragraph.paragraph as textProps).bold}
                                  italic={(paragraph.paragraph as textProps).italic}
                                  underline={(paragraph.paragraph as textProps).underline}
                                />
                                {(paragraph.paragraph as textProps).breakLine && (
                                  <div>
                                    <br />
                                  </div>
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    )
                  case PARAGRAPH_TYPES.SUBTITLE:
                    return (
                      <>
                        <div
                          id={paragraph.id ? paragraph.id : ''}
                          className='content-section-subtitle'
                        >
                          <span>
                            <Text
                              text={(paragraph.paragraph as textProps).text}
                              a={(paragraph.paragraph as textProps).a}
                              link={(paragraph.paragraph as textProps).link}
                              target={(paragraph.paragraph as textProps).target}
                              bold={(paragraph.paragraph as textProps).bold}
                              italic={(paragraph.paragraph as textProps).italic}
                              underline={(paragraph.paragraph as textProps).underline}
                            />
                            {(paragraph.paragraph as textProps).breakLine && (
                              <div>
                                <br />
                              </div>
                            )}
                          </span>
                        </div>
                      </>
                    )
                  case PARAGRAPH_TYPES.TABLE:
                    return (
                      <Table
                        paragraph={paragraph.paragraph as { [key: string]: textProps[] }}
                        id={paragraph.id}
                        headers={paragraph.headers as textProps[]}
                      />
                    )
                  default:
                    return
                }
              },
            )}
          </>
        )
      })}
    </div>
  )
}

export default PrivacyContentSection
