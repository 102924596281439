import { FC, useEffect, useState } from 'react'

import cx from 'classnames'

import avatarPlaceholder from 'assets/images/avatar.png'
import { TEAM_FIRST_COLOR_PLACEHOLDER, TEAM_SECOND_COLOR_PLACEHOLDER } from 'constants/teams'

import './Avatar.scss'

interface IAvatarProps {
  className?: string
  firstColor?: string
  height?: string
  image?: string
  placeholder?: string
  secondColor?: string
  width?: string
  backgroundColor?: string
}

const Avatar: FC<IAvatarProps> = ({
  className,
  firstColor,
  height = '56px',
  image,
  placeholder = avatarPlaceholder,
  secondColor,
  width = '56px',
  backgroundColor
}) => {
  const [avatar, setAvatar] = useState<string>('')

  useEffect(() => {
    if (image) {
      setAvatar(image === 'string' ? determinePlaceholder() : image)
    } else {
      setAvatar(determinePlaceholder())
    }
  }, [placeholder, image])

  const determinePlaceholder = () => {
    return placeholder === 'color' ? '' : placeholder
  }

  return (
    <div className={cx('avatar', className)} style={{ height: `${height}`, width: `${width}`, backgroundColor: backgroundColor || 'var(--color-white)'}}>
      {avatar ? (
        <img className='avatar-image' src={avatar} alt='Avatar' referrerPolicy='no-referrer' />
      ) : (
        <div className='avatar-colors'>
          <div style={{ backgroundColor: firstColor || TEAM_FIRST_COLOR_PLACEHOLDER }} />
          <div style={{ backgroundColor: secondColor || TEAM_SECOND_COLOR_PLACEHOLDER }} />
        </div>
      )}
    </div>
  )
}

export default Avatar
