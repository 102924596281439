import { ButtonHTMLAttributes, ReactElement, forwardRef } from 'react'
import cx from 'classnames'

import './Button.scss'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  text: string
  children?: ReactElement[] | ReactElement
}

const Button = forwardRef<HTMLButtonElement, IButtonProps>((props, ref) => {
  const { text, className, children } = props

  return (
    <button
      ref={ref} // Forward the ref here
      {...props}
      className={cx('btn', className)}
    >
      {children}
      {text}
    </button>
  )
})

export default Button
